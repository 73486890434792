var e4   = e4 || {},
    dita = dita || {};
( function () {
    'use strict';
    jQuery( function () {
        dita.init();
    } );
    dita.init         = function () {
        dita.dealersearch.init();
    };
    dita.dealersearch = {
        options: {
            url: '/system/data/dealersearch',
            lang: jQuery( 'html' ).attr( 'lang' )
        },
        notChangedYet: true,
        init: function () {
            if ( jQuery( '#js-e-dealersearch-container' ).length === 1 ) {
                e4.util.require.js( e4.settings.require.handlebars, function () {
                    e4.handlebars.registerHelpers( 'compare' );
                    e4.handlebars.registerTemplates( '#js-e-handlebars-tmpl-dealersearch' );
                    dita.dealersearch.bindEvents();
                    dita.dealersearch.get();
                } );
            }
        },
        bindEvents: function () {
            jQuery( '#js-e-dealersearch-container' ).on( 'change', '.js-dita-lawyer-selector', function () {

                jQuery( '.js-dita-lawyer' ).hide();
                
                    var ditaSelectorPlacering = jQuery( '.js-dita-lawyer-selector[data-selector="placering"]' ).val() === '' ? '' : '[data-placering-code*="' + jQuery( '.js-dita-lawyer-selector[data-selector="placering"]' ).val() + '"]';
                    var ditaSelectorCompany   = jQuery( '.js-dita-lawyer-selector[data-selector="company"]' ).val() === '' ? '' : '[data-company-code="' + jQuery( '.js-dita-lawyer-selector[data-selector="company"]' ).val() + '"]';
                    var ditaSelectorSpeciale  = jQuery( '.js-dita-lawyer-selector[data-selector="speciale"]' ).val() === '' ? '' : '[data-speciale-code*="' + jQuery( '.js-dita-lawyer-selector[data-selector="speciale"]' ).val() + '"]';
                    var ditaHasValues         = jQuery( '.js-dita-lawyer' + ditaSelectorPlacering + ditaSelectorCompany + ditaSelectorSpeciale );
                    ditaHasValues.fadeIn( 'slow' );
                    if ( ditaHasValues.length === 0 ) {
                        jQuery( '.dita-no-results' ).removeClass( 'd-none' );
                    }
                    else {
                        jQuery( '.dita-no-results' ).addClass( 'd-none' );
                    }
                    jQuery( '.dita-intro-text' ).addClass( 'd-none' );
                    
            } );
        },
        get: function () {
            jQuery.ajax( {
                url: dita.dealersearch.options.url,
                type: 'GET',
                data: {
                    Groups: jQuery('.js-find-lawyer-group-name').val()
                },
                dataType: 'json'
            } )
                    .done( function ( data ) {
                        dita.dealersearch.build( data );
                    } )
                    .fail( function ( error ) {
                        console.error( error );
                    } );
        },
        build: function ( data ) {
            var jContainer = jQuery( '#js-e-dealersearch-container' );
           
            data.specialList = data.specialList.sort();
            data.placeringList = data.placeringList.sort();
            var sortedCompanyList = data.companyList.sort();
            
            jQuery.each( sortedCompanyList, function ( index, company ) {
                if ( company.value === jContainer.data( 'default-company' ) ) {
                    company.isSelected = true;
                }
            } );
            jContainer.html( e4.handlebars.tmpl.dealersearch( data ) );
//            jQuery( '.js-dita-lawyer' ).hide();
        }
    };
}() );

